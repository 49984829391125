// Packages
import React from "react";
import { graphql, Link } from "gatsby";
import { LocationMarkerIcon, UsersIcon, EmojiHappyIcon, CalendarIcon, ClockIcon } from "@heroicons/react/outline";

// Types
import { IGatsbyImageData } from "gatsby-plugin-image";
import type { SiteMetadata, Location } from "~types/Gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import P from "~components/typography/P";
import Grid from "~components/common/Grid";
import Hero from "~components/sections/Hero";
import SubTitle from "~components/typography/SubTitle";
import Ul from "~components/typography/Ul";
import Button from "~components/common/Button";
import H2 from "~components/typography/H2";
import Input from "~components/forms/elements/Input";
import FeatureIcon from "~components/features/FeatureIcon";
import Datenschutz from "~components/forms/elements/Datenschutz";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Einladung"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Hero image={data.imageHeader} alt="">
				<SubTitle className="mb-2">Exklusive und limitierte Einladung</SubTitle>

				<H2 className="mb-4">LEVEL UP YOUR SKILLS</H2>

				<Ul
					items={[
						"2-3 Semester weniger studieren",
						"Bessere Leistungen in Klausuren",
						"Aktivierung Deiner vollen Brainpower",
						"100-200% schneller Lesen und dabei X-Fach mehr merken und behalten",
						"Erhöhtes Textverständnis und Inhalte besser verstehen",
						"Prüfungsangst überwinden",
						"Intelligenter Fachwissen aneignen",
					]}
					pretty
					className="mb-3"
				/>

				<P className="mb-3">
					Hiermit bekommst Du eine einmalige Gelegenheit kostenlos an meinem Workshop: &ldquo;SpeedReading&ldquo; teil
					zunehmen.
				</P>

				<P className="mb-3">
					Lerne die Geheimnisse der Elite Universitäten kennen und entfalte Dein volles Potential im Studentenalltag
					oder im Berufsalltag.
				</P>
				<P className="mb-3">Schnell sein lohnt sich auch hier.</P>
				<P className="mb-3">Klicke auf die Homepage und trag Dich bitte verbindlich ein.</P>
				<P className="mb-10">Ich freue mich auf dieses außergewöhnliche Event mit dir.</P>

				<Button anchor="/einladung#teilnahme" text="Zur kostenlosen Teilnahme" />
			</Hero>

			<Container>
				<div className="text-center"></div>
				<Grid cols={2}>
					<div className="relative mb-8 md:order-2" id="teilnahme">
						<Form />
					</div>
					<dl className="grid grid-cols-1 gap-8 md:order-1 md:grid-cols-2">
						<FeatureIcon
							title="Wann?"
							description="Sonntag 02.10.2022"
							icon={CalendarIcon}
							align="center"
							color="blue"
						/>
						<FeatureIcon
							title="Wie lange?"
							description="09:30 Uhr Come together // Workshop von 10:00 Uhr bis 18:00 Uhr"
							icon={ClockIcon}
							align="center"
							color="blue"
						/>
						<FeatureIcon
							title="Wo?"
							description="In Präsenz über den Dächern von Kassel."
							icon={LocationMarkerIcon}
							align="center"
							color="blue"
						/>
						<FeatureIcon
							title="Wie viele?"
							description="Maximal 10 Teilnehmer."
							icon={UsersIcon}
							align="center"
							color="blue"
						/>
						<FeatureIcon
							title="Was noch?"
							description="Inclusive Mittagessen und Verpflegung!"
							icon={EmojiHappyIcon}
							align="center"
							color="blue"
						/>
					</dl>
				</Grid>
			</Container>
		</Layout>
	);
}

export const Form = () => {
	return (
		<div>
			<SubTitle className="mb-2">Kostenlos teilnehmen</SubTitle>
			<H2 className="mb-4">Trage Dich jetzt ein!</H2>
			<P className="mb-3">Melde Dich an und sei beim SpeedReading Workshop dabei</P>

			<form name="Einladung" method="POST" data-netlify="true" action="/anfrage-gesendet/" className="space-y-4">
				<input type="hidden" name="form-name" value="Einladung" />
				<Input type="text" label="Vorname" id="vorname" name="Vorname" placeholder="Dein Vorname" required />
				<Input type="text" label="Nachname" id="nachname" name="Nachname" placeholder="Dein Nachname" required />

				<Input
					type="email"
					label="E-Mail"
					id="email"
					name="E-Mail"
					placeholder="Deine E-Mailadresse"
					autoComplete="email"
					required
				/>
				<Input
					type="tel"
					label="Telefon"
					id="telefon"
					name="Telefon"
					placeholder="Deine Telefonnummer"
					autoComplete="tel"
					inputMode="numeric"
					required
				/>

				<div className="pt-3">
					<Button submit text="Platz sichern" className="mt-3 mb-2 w-full" />
					<Datenschutz />
				</div>
			</form>
		</div>
	);
};

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageHeader: file(relativePath: { eq: "pages/einladung/einladung_header.jpg" }) {
			childImageSharp {
				gatsbyImageData(quality: 90)
			}
		}
	}
`;
